import Vue from "vue";
import Vuex from "vuex";
import events from "./modules/events";
import login from "./modules/login";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    events,
    login,
  },
});
