import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";

const state = {
  user: null,
};

const getters = {
  getUser: (state) => state.user,
};

const actions = {
  async logIn({ commit }, userCredential) {
    try {
      const auth = getAuth();
      const res = await signInWithEmailAndPassword(
        auth,
        userCredential.email,
        userCredential.password
      );
      const user = res.user;
      commit("SET_USER", user);
    } catch (error) {
      console.error(error);
    }
  },
  async logOut({ commit }) {
    const auth = getAuth();
    await signOut(auth);
    commit("SET_USER", null);
  },
  async authStateChanged({ commit }) {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("state changed: log in");
        console.log(user);
        commit("SET_USER", user);
      } else {
        console.log("state changed: log out");
        commit("SET_USER", null);
      }
    });
  },
};

const mutations = {
  SET_USER: (state, data) => (state.user = data),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
