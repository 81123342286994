<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    :fullscreen="$vuetify.breakpoint.mobile"
    :hide-overlay="$vuetify.breakpoint.mobile"
    :transition="
      $vuetify.breakpoint.mobile
        ? 'dialog-bottom-transition'
        : 'scale-transition'
    "
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
        class="d-flex align-center justify-center flex-column"
        style="height: 100%;"
      >
        <v-icon color="secondary" size="100">mdi-plus-circle</v-icon>
        <div class="mt-6 text-h6 secondary--text">
          Nowa sesja
        </div>
      </div>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Dodaj sesję</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <div class="d-flex align-center">
            <v-btn
              color="secondary"
              @click.prevent="addSessionClick"
              :loading="loader"
              :disabled="!valid"
            >
              Zapisz
            </v-btn>
          </div>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form class="mt-4" v-model="valid" ref="form">
          <v-text-field
            color="secondary"
            label="Tytuł"
            class="mb-n2"
            outlined
            v-model="title"
            :rules="titleRules"
          >
          </v-text-field>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Data"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                outlined
                color="secondary"
                required
                :rules="dateRules"
              ></v-text-field>
            </template>
            <v-date-picker v-model="date" no-title scrollable locale="pl">
              <v-spacer></v-spacer>
              <v-btn text color="secondary" @click="menu = false">
                Anuluj
              </v-btn>
              <v-btn text color="secondary" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-file-input
            v-model="photo"
            append-icon="mdi-image-outline"
            prepend-icon=""
            label="Dodaj zdjęcie"
            color="secondary"
            outlined
            required
            :rules="photoRules"
          >
          </v-file-input>
          <div v-for="(gallery, i) in galleryUrls" :key="i" class="d-flex">
            <v-text-field
              v-model="gallery.url"
              label="Link do galerii"
              append-icon="mdi-link"
              outlined
              color="secondary"
            ></v-text-field>
            <v-btn
              class="mt-2"
              icon
              color="secondary"
              @click.prevent="galleryUrls.splice(i, 1)"
            >
              <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
          </div>
          <v-btn text color="secondary" @click.prevent="addLinkClick">
            <v-icon color="secondary" class="mr-2"
              >mdi-plus-circle-outline</v-icon
            >dodaj link
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data: () => ({
    valid: true,
    dialog: false,
    menu: false,
    loader: false,
    title: "MOTO-JAKUSZYCE",
    titleRules: [(v) => !!v || "Tytuł wymagany"],
    photo: null,
    photoRules: [(v) => !!v || "Zdjcie wymagane"],
    date: new Date().toISOString().slice(0, 10),
    dateRules: [(v) => !!v || "Data wymagana"],
    galleryUrls: [
      {
        id: 0,
        url: "",
      },
    ],
  }),
  methods: {
    ...mapActions(["addSession"]),
    addLinkClick() {
      this.galleryUrls.push({ id: 0, url: "" });
    },
    async addSessionClick() {
      if (!this.$refs.form.validate()) return;
      this.loader = true;
      let session = {
        title: this.title,
        file: this.photo,
        date: this.date,
        galleryUrls: this.galleryUrls.map((v) => v.url),
      };
      let result = await this.addSession(session);
      this.loader = false;
      if (result) {
        this.dialog = false;
        this.$refs.form.reset();
      }
    },
  },
};
</script>
