var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"900","fullscreen":_vm.$vuetify.breakpoint.mobile,"hide-overlay":_vm.$vuetify.breakpoint.mobile,"transition":_vm.$vuetify.breakpoint.mobile
      ? 'dialog-bottom-transition'
      : 'scale-transition'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center flex-column",staticStyle:{"height":"100%"}},'div',attrs,false),on),[_c('v-icon',{attrs:{"color":"secondary","size":"100"}},[_vm._v("mdi-plus-circle")]),_c('div',{staticClass:"mt-6 text-h6 secondary--text"},[_vm._v(" Nowa sesja ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Dodaj sesję")]),_c('v-spacer'),_c('v-toolbar-items',[_c('div',{staticClass:"d-flex align-center"},[_c('v-btn',{attrs:{"color":"secondary","loading":_vm.loader,"disabled":!_vm.valid},on:{"click":function($event){$event.preventDefault();return _vm.addSessionClick($event)}}},[_vm._v(" Zapisz ")])],1)])],1),_c('v-card-text',[_c('v-form',{ref:"form",staticClass:"mt-4",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{staticClass:"mb-n2",attrs:{"color":"secondary","label":"Tytuł","outlined":"","rules":_vm.titleRules},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Data","append-icon":"mdi-calendar","readonly":"","outlined":"","color":"secondary","required":"","rules":_vm.dateRules},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","locale":"pl"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Anuluj ")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1),_c('v-file-input',{attrs:{"append-icon":"mdi-image-outline","prepend-icon":"","label":"Dodaj zdjęcie","color":"secondary","outlined":"","required":"","rules":_vm.photoRules},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}}),_vm._l((_vm.galleryUrls),function(gallery,i){return _c('div',{key:i,staticClass:"d-flex"},[_c('v-text-field',{attrs:{"label":"Link do galerii","append-icon":"mdi-link","outlined":"","color":"secondary"},model:{value:(gallery.url),callback:function ($$v) {_vm.$set(gallery, "url", $$v)},expression:"gallery.url"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"icon":"","color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.galleryUrls.splice(i, 1)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)}),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){$event.preventDefault();return _vm.addLinkClick($event)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary"}},[_vm._v("mdi-plus-circle-outline")]),_vm._v("dodaj link ")],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }