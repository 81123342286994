import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Photography from "../views/Photography.vue";
import Sessions from "../views/Sessions.vue";
import Login from "../views/Login.vue";
import { getAuth } from "firebase/auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/oferta/:id",
    name: "Photography",
    component: Photography,
  },
  {
    path: "/sessions",
    name: "Sessions",
    component: Sessions,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresGuest: true,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  const auth = getAuth()
  // Check for requiresAuth guard
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Check if NO logged user
    const isAuthenticated = await auth.currentUser;
    if (!isAuthenticated) {
      // Go to login
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      // Proceed to route
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    // Check if logged user
    const isAuthenticated = await auth.currentUser;
    if (isAuthenticated) {
      // Go to home
      next({
        path: "/",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      // Proceed to route
      next();
    }
  } else {
    // Proceed to route
    next();
  }
});

export default router;
