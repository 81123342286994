<template>
  <div>
    <v-app-bar app color="primary">
      <v-app-bar-nav-icon light @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <div class="d-flex align-center mx-auto">
        <v-img alt="MOTO.KADR" contain min-width="100" src="@/assets/logo2.png" width="160" @click="onLogoClick" />
      </div>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list light>
        <div v-if="$route.name === 'Home'">
          <v-list-item v-for="(route, i) in routes" :key="i" @click="$vuetify.goTo(route.to)">
            <v-list-item-action>
              <v-icon>{{ route.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="route.name" />
            </v-list-item-content>
          </v-list-item>
          <contact />
          <div v-if="getUser">
            <v-list-item @click="$router.push('/sessions')">
              <v-list-item-action>
                <v-icon>mdi-lock</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Admin</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logOutClick">
              <v-list-item-action>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Wyloguj</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </div>
        <div v-else>
          <v-list-item to="/">
            <v-list-item-action>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Contact from "@/components/Contact";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Contact,
  },
  data: () => ({
    drawer: false,
    routes: [
      {
        name: "Home",
        icon: "mdi-view-dashboard",
        to: "#home",
      },
      {
        name: "O mnie",
        icon: "mdi-account",
        to: "#about",
      },
      {
        name: "Nadchodzące wydarzenie",
        icon: "mdi-calendar",
        to: "#event",
      },
      {
        name: "Oferta",
        icon: "mdi-camera",
        to: "#offer",
      },
      {
        name: "Sesje",
        icon: "mdi-image-multiple",
        to: "#sessions",
      },
    ],
  }),
  computed: {
    ...mapGetters(["getUser"]),
  },
  methods: {
    ...mapActions(["logOut"]),
    async logOutClick() {
      await this.logOut();
    },
    async onLogoClick() {
      if (this.$route.name !== "/") {
        try {
          await this.$router.push("/");
        } catch (_) {
          // Duplicate navigation
        }
      }
    },
  },
};
</script>
