<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-action>
          <v-icon>mdi-phone</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Kontakt</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-card>
      <v-card-title> Kontakt </v-card-title>

      <v-card-text>
        <div class="caption">Telefon</div>
        <div class="subtitle-2 black--text">664 466 684</div>
        <div class="caption">E-mail</div>
        <div class="subtitle-2 black--text">moto.kadr@gmail.com</div>
        <div class="caption">Facebook</div>
        <div class="subtitle-2 black--text">MotoKadr.com</div>
        <div class="caption">Nazwa</div>
        <div class="subtitle-2 black--text">SW-PHOTO</div>
        <div class="caption">Imię i Nazwisko</div>
        <div class="subtitle-2 black--text">Violetta Wojtowicz</div>
        <div class="caption">Numer konta</div>
        <div class="subtitle-2 black--text">11 1020 2124 0000 8502 0028 6815</div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="dialog = false"> zamknij </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
  }),
};
</script>