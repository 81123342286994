<template>
  <v-container class="pt-6">
    <v-row>
      <v-col cols="12">
        <v-card
          :rounded="$vuetify.breakpoint.mobile ? '' : 'xl'"
          class="text-center py-5"
        >
          <div v-for="(upComingEvent, i) in getUpComingEvents" :key="i">
            <div class="font-weight-bold text-h5">
              {{ upComingEvent.title }}
            </div>
            <div class="subtitle-2">{{ upComingEvent.date }}</div>
            <div class="px-4 px-md-10">
              {{ upComingEvent.text }}
            </div>
            <v-divider
              v-if="i < getUpComingEvents.length - 1"
              class="my-2"
            ></v-divider>
          </div>

          <edit-event></edit-event>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-text>
            <add-new-session></add-new-session>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-for="(session, i) in getSessions" :key="i" cols="12" md="3">
        <v-img :src="session.downloadUrl">
          <div class="white text-center" style="opacity: 0.8">
            {{ session.title }} -
            <span class="secondary--text">{{ session.date }}</span>
          </div>
          <delete-session :session="session"></delete-session>
        </v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AddNewSession from "@/components/AddNewSession";
import EditEvent from "@/components/EditEvent";
import DeleteSession from "@/components/DeleteSession.vue";
export default {
  components: {
    AddNewSession,
    EditEvent,
    DeleteSession,
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions(["subscribeSessions"]),
  },
  computed: {
    ...mapGetters(["getSessions", "getUpComingEvents"]),
  },
  mounted() {
    this.subscribeSessions();
  },
};
</script>

<style>
.bottom-delete {
  position: absolute;
  right: 0;
  bottom: 0;
  border-top-left-radius: 15px;
}
</style>
