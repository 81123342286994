<template>
  <v-dialog
    v-model="dialog"
    width="700"
    eager
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" large outlined dark>
        Sprawdź ofertę
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        >{{ pricing ? pricing.title : "" }} <v-spacer></v-spacer
        ><v-btn icon @click="dialog = false"
          ><v-icon>mdi-close</v-icon></v-btn
        ></v-card-title
      >

      <!-- <v-card-text v-html="pricing ? pricing.text : ''" /> -->
      <v-card-text>
        <div v-for="(paragraph, i) in pricing.paragraphs" :key="i">
          <div class="text-h6 black--text" v-if="paragraph.title">
            {{ paragraph.title }}
          </div>
          <div v-html="paragraph.content"></div>
          <div v-if="paragraph.imageRow" class="mb-3">
            <v-row class="px-2">
              <v-col cols="12" md="6"
                ><v-img
                  src="/assets/fotoksiazki/1J4A6760-HDR.jpg"
              /></v-col>
              <v-col cols="12" md="6"
                ><v-img
                  src="/assets/fotoksiazki/1J4A8796-HDR.jpg"
              /></v-col>
              <v-col cols="12" md="6"
                ><v-img src="/assets/fotoksiazki/1J4A7721.jpg" max-height="180"
              /></v-col>
              <v-col cols="12" md="6"
                ><v-img src="/assets/fotoksiazki/1J4A7730.jpg" max-height="180"
              /></v-col>
              <v-col cols="12" md="6"
                ><v-img
                  src="/assets/fotoksiazki/1J4A7832-HDR.jpg"
                  max-height="200"
              /></v-col>
              <v-col cols="12" md="6"
                ><v-img src="/assets/fotoksiazki/1J4A7737.jpg" max-height="200"
              /></v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions v-if="!$vuetify.breakpoint.mobile">
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="dialog = false"> zamknij </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["pricing"],
  data: () => ({
    dialog: false,
  }),
};
</script>
