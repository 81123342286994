<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    :fullscreen="$vuetify.breakpoint.mobile"
    :hide-overlay="$vuetify.breakpoint.mobile"
    :transition="
      $vuetify.breakpoint.mobile
        ? 'dialog-bottom-transition'
        : 'scale-transition'
    "
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="secondary" class="mt-3">
        edytuj
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="primary">
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Edytuj wydarzenie</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <div class="d-flex align-center">
            <v-btn
              color="secondary"
              :disabled="loader"
              :loading="loader"
              @click.prevent="saveClick"
            >
              Zapisz
            </v-btn>
          </div>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-form class="mt-4" v-model="valid" ref="form">
          <!-- <div v-for="(event, i) in getUpComingEvents" :key="i"> -->
          <v-text-field
            color="secondary"
            label="Tytuł"
            outlined
            v-model="getUpComingEvents[0].title"
            :rules="titleRules"
          >
          </v-text-field>
          <v-text-field
            color="secondary"
            label="Data"
            placeholder="18-19.09.2021"
            outlined
            v-model="getUpComingEvents[0].date"
            :rules="dateRules"
          >
          </v-text-field>
          <v-textarea
            label="Tekst"
            color="secondary"
            v-model="getUpComingEvents[0].text"
            auto-grow
            rows="1"
            outlined
            :rules="textRules"
          >
          </v-textarea>
          <!-- </div> -->
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    dialog: false,
    valid: true,
    loader: false,
    title: "MOTO-JAKUSZYCE",
    titleRules: [(v) => !!v || "Tytuł wymagany"],
    date: "",
    dateRules: [(v) => !!v || "Data wymagana"],
    text: "",
    textRules: [(v) => !!v || "Tekst wymagany"],
  }),
  computed: {
    ...mapGetters(["getUpComingEvents"]),
  },
  methods: {
    ...mapActions(["changeUpComingEvent"]),
    async saveClick() {
      this.loader = true;
      await this.changeUpComingEvent({
        title: this.getUpComingEvents[0].title,
        text: this.getUpComingEvents[0].text,
        date: this.getUpComingEvents[0].date,
        id: this.getUpComingEvents[0].id,
      });
      this.loader = false;
      setTimeout(() => {
        this.dialog = false;
      }, 1000);
    },
  },
};
</script>
