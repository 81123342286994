<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" color="secondary" class="mt-4"
        >Zobacz</v-btn
      >
    </template>

    <v-card class="pt-3">
      <div class="text-center text-h6">
        {{ event.title }} - {{ event.date }}
      </div>

      <v-card-text>
        <v-row class="pt-4">
          <v-col cols="6" v-for="(galleryUrl, i) in event.galleryUrls" :key="i">
            <a :href="galleryUrl" style="text-decoration: none;" target="_blank">
              <v-card rounded="xl">
                <v-sheet class="grey d-flex justify-center">
                  <v-icon size="52">mdi-google-drive</v-icon>
                </v-sheet>
                <div
                  class="text-md-subtitle-1 text-subtitle-2 grey--text text--darken-2 text-center py-2"
                >
                  Galeria {{ galleryText[i] }}
                </div>
              </v-card>
            </a>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="dialog = false"> zamknij </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['event'],
  data: () => ({
    dialog: false,
    galleryText: [
      'pierwsza',
      'druga',
      'trzecia',
      'czwarta',
      'piąta',
      'szósta',
      'siódma',
      'ósma',
      'dziewiąta',
      'dziesiąta',
      'jedenasta',
      'dwunasta',
    ],
  }),
}
</script>