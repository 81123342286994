<template>
  <v-dialog v-model="dialog" width="500">
    <template v-slot:activator="{ on, attrs }">
      <div class="white bottom-delete">
        <v-btn color="error" icon v-bind="attrs" v-on="on">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </template>

    <v-card>
      <v-card-title class="text-h5 secondary white--text lighten-2"
        >Usunąć sesje?
      </v-card-title>

      <v-card-actions>
        <v-btn text @click="dialog = false">anuluj</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="deleteSessionClick"
          :loading="loader"
        >
          Usuń
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: ["session"],
  data: () => ({
    dialog: false,
    loader: false,
  }),
  methods: {
    ...mapActions(["deleteSession"]),
    async deleteSessionClick() {
      this.loader = true;
      await this.deleteSession(this.session.id);
      this.loader = false;
      setTimeout(() => {
        this.dialog = false;
      }, 1000);
    },
  },
};
</script>

<style>
.bottom-delete {
  position: absolute;
  right: 0;
  bottom: 0;
  border-top-left-radius: 15px;
}
</style>
