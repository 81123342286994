<template>
  <v-app>
    <Navbar />
    <v-main>
      <router-view />
    </v-main>
    <v-footer>
      <v-container>
        <div class="text-h4">Kontakt</div>
        <div>
          Email:
          <a style="text-decoration: none;" class="secondary--text" href="mailto: moto.kadr@gmail.com"
            >moto.kadr@gmail.com</a
          >
        </div>
        <div>
          Tel:
          <a style="text-decoration: none;" class="secondary--text" href="tel: 664 466 684">664 466 684</a>
        </div>
        <div>
          Płatności na konto:
          <span class="secondary--text">Violetta Wojtowicz</span>
        </div>
        <div>
          Nr konta:
          <span class="secondary--text">11 1020 2124 0000 8502 0028 6815</span>
        </div>
        <div>
          Facebook:
          <a
            target="_blank"
            style="text-decoration: none;"
            class="secondary--text"
            href="https://www.facebook.com/motokadrcom"
            >motokadrcom</a
          >
        </div>
        <!-- <div>
          Płatności
          <a
            target="_blank"
            style="text-decoration: none;"
            class="secondary--text"
            href="https://www.paypal.com/paypalme/motokadrcom?country.x=PL&locale.x=pl_PL&fbclid=IwAR1Se2EtMapT7K6LzsEUHMpijPlWP1fi4Qh2y8nToUamhqHSwcrmfQ5LdME"
            >PayPal</a
          >
        </div>
        <div>
          Płatności
          <span style="text-decoration: none;" class="secondary--text">BLIK</span>
        </div> -->
      </v-container>
      <div class="mx-auto">
        <span>&copy; Motokadr {{ new Date().getFullYear() }} </span>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import Navbar from "@/components/navigation/Navbar";
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions(["authStateChanged"]),
  },
  beforeMount() {
    this.authStateChanged;
  },
};
</script>
