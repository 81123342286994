<template>
  <v-container fluid class="pa-0">
    <v-img src="@/assets/backimg.jpg" class="d-flex align-center">
      <v-card class="mx-auto mt-n16" max-width="700">
        <v-form ref="logInForm" v-model="valid" class="pa-6">
          <!-- <v-alert
            transition="slide-y-transition"
            dense
            :type="getAlert.success ? 'success' : 'error'"
            rounded="0"
            v-model="getAlert.show"
            class="mb-2"
          >
            {{ getAlert.text }}
          </v-alert> -->
          <v-text-field
            color="secondary"
            label="Email"
            class="mb-n2"
            outlined
            v-model="email"
            :rules="emailRules"
          >
          </v-text-field>
          <v-text-field
            color="secondary"
            label="Hasło"
            :type="show ? 'text' : 'password'"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="show = !show"
            outlined
            v-model="password"
            :rules="passwordRules"
            @keypress="eneterPressed"
          >
          </v-text-field>
          <div class="px-10">
            <v-btn
              width="100%"
              color="secondary"
              class="font-weight-bold mb-2"
              large
              :loading="loader"
              :disabled="!valid"
              @click="logInClick"
              >Login</v-btn
            >
          </div>
        </v-form>
      </v-card>
    </v-img>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    valid: true,
    show: false,
    email: "",
    emailRules: [
      (v) => !!v || "E-mail jest wymagany",
      (v) => /.+@.+\..+/.test(v) || "E-mail musi być poprawny",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "Hasło wymagane",
      (v) => v.length >= 6 || "Hasło musi mieć conajmniej 6 znaków",
    ],
    loader: false,
  }),
  methods: {
    ...mapActions(["logIn"]),
    reset() {
      this.$refs.logInForm.reset();
      this.email = "";
      this.password = "";
    },
    resetValidation() {
      this.$refs.logInForm.resetValidation();
    },
    async logInClick() {
      if (!this.$refs.logInForm.validate()) return;

      this.loader = true;
      const data = {
        email: this.email,
        password: this.password,
      };
      await this.logIn(data);
      this.loader = false;
    },
    eneterPressed(event) {
      if (event.key == "Enter") this.logInClick();
    },
  },
  computed: {
    ...mapGetters(["getUser"]),
    user() {
      return this.getUser;
    },
  },
  watch: {
    user(value) {
      if (value) {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style></style>
