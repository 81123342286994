<template>
  <v-container fluid class="pa-0">
    <section id="headBanner">
      <v-img
        :src="photography.imageUrl"
        :height="$vuetify.breakpoint.smAndDown ? 850 : 640"
      >
        <v-theme-provider dark>
          <v-container fill-height>
            <v-row
              align="center"
              class="mt-n10 white--text mx-auto"
              justify="center"
            >
              <v-col
                class="white--text text-center text-uppercase"
                cols="12"
                tag="h1"
              >
                <span
                  :class="[
                    $vuetify.breakpoint.smAndDown ? 'display-2' : 'display-4',
                  ]"
                  class="font-weight-bold text-uppercase"
                >
                  fotografia<br /><span
                    class="secondary--text"
                    :class="[$vuetify.breakpoint.smAndDown ? 'text-h4' : '']"
                  >
                    {{ photography.title }}</span
                  >
                </span>
              </v-col>

              <v-btn
                class="align-self-end"
                fab
                outlined
                @click="$vuetify.goTo('#photographyGallery')"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-theme-provider>
      </v-img>
    </section>

    <section id="photographyDescription">
      <v-container fluid class="px-0 py-5 black white--text text-center">
        <v-responsive
          class="mx-auto title font-weight-light my-8"
          max-width="720"
          v-html="photography.description"
        >
        </v-responsive>

        <v-responsive class="mx-auto mb-4">
          <pricing v-if="photography.pricing" :pricing="photography.pricing" />
        </v-responsive>
      </v-container>
    </section>

    <section id="photographyGallery" class="black">
      <v-row class="py-5 px-md-5 px-lg-10">
        <v-col
          v-for="(photo, i) in photography.galleryUrls"
          :key="i"
          class="d-flex child-flex"
          cols="12"
          md="4"
        >
          <v-img
            :src="photo"
            :lazy-src="photo"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </section>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import Pricing from "@/components/Pricing";

export default {
  components: {
    Pricing,
  },
  computed: {
    ...mapGetters(["getOffer"]),
  },
  data: () => ({
    photography: {
      title: "",
      text: "",
      route: "",
      imageUrl: "",
    },
  }),
  mounted() {
    this.photography = this.getOffer.find(
      (v) => v.route === `oferta/${this.$route.params.id}`
    );
    window.scrollTo(0, 0);
  },
};
</script>
