<template>
  <div>
    <section id="home">
      <v-parallax :height="$vuetify.breakpoint.smAndDown ? 850 : 800" src="@/assets/backimg.jpg">
        <v-theme-provider dark>
          <v-container fill-height>
            <v-row align="center" class="mt-n10 white--text mx-auto" justify="center">
              <v-col class="white--text text-center" cols="12" tag="h1">
                <span
                  :class="[$vuetify.breakpoint.smAndDown ? 'display-2 ml-n6' : 'display-4']"
                  class="font-weight-bold secondary--text"
                >
                  MotoKadr
                </span>
              </v-col>

              <v-btn class="align-self-end" fab outlined @click="$vuetify.goTo('#about')">
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </v-row>
          </v-container>
        </v-theme-provider>
      </v-parallax>
    </section>

    <section id="about">
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3 mt-5 text-uppercase">
          o mnie
        </h2>

        <v-responsive class="mx-auto mb-8" width="126">
          <v-divider class="mb-1 black" />
        </v-responsive>

        <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
          Nazywam się Sylwester Wojtowicz i jestem zawodowym fotografem. Wieloletnia pasja do fotografii i motocykli,
          zaowocowała pomysłem na fotografię motocyklową. Głównie fotografuję motocyklistów w Karkonoszach na drodze
          krajowej nr 3, zwanej „ Szosą Czeską” między Szklarską Porębą a Jakuszycami. Fotografuje również po Czeskiej
          stronie Karkonoszy, na drodze nr 44 na przełęczy „Cervenohorskie Sedlo” oraz na drodze nr 11 Cervena Voda -
          Jablonne nad Orlici. Wszystkie te miejsca to kręte widokowe drogi idealne na motocyklowe wypady. Aktualnie w
          mojej pracy używam profesjonalnego sprzętu marki CANON : - EOS R5, Mark IV - EF 24-70 mm f/2.8L II USM - EF
          70-200 mm f/2.8L III USM - EF 16-35 mm f/2.8L II USM oraz Extrender EF 1.4x III. Każda nowa sesja to wyzwanie
          które traktuje indywidualnie i podchodzę do jej realizacji z całkowitym zaangażowaniem. Fotografia jest dla
          mnie pasją i sposobem na życie.
        </v-responsive>

        <v-responsive class="mx-auto mb-4">
          <v-btn target="_blank" href="https://www.facebook.com/motokadrcom" large outlined
            ><v-icon class="mr-2">mdi-facebook</v-icon> Facebook
          </v-btn>
        </v-responsive>
      </v-container>
    </section>

    <section id="event">
      <v-parallax :height="$vuetify.breakpoint.smAndDown ? 950 : 800" src="/assets/environment/1J4A8232.jpg">
        <v-theme-provider dark>
          <v-container fill-height class="text-center">
            <div class="d-flex flex-column" style="width: 100%">
              <h2 class="display-2 font-weight-bold mb-3 mt-5 text-uppercase">
                nadchodzące wydarzenie
              </h2>

              <v-responsive class="mx-auto mb-8" width="126">
                <v-divider class="mb-1 white" />
              </v-responsive>
              <v-card light rounded="xl">
                <v-row>
                  <v-col cols="12" md="6" class="d-flex align-center">
                    <div class="mt-md-n6 mt-3">
                      <div v-for="(upComingEvent, i) in getUpComingEvents" :key="upComingEvent.id">
                        <div class="font-weight-bold text-h5">
                          {{ upComingEvent.title }}
                        </div>
                        <div class="subtitle-2">{{ upComingEvent.date }}</div>
                        <div class="px-4 px-md-10">
                          {{ upComingEvent.text }}
                        </div>
                        <v-divider v-if="i < getUpComingEvents.length - 1" class="my-2"></v-divider>
                      </div>
                      <!--<v-divider class="my-2"></v-divider>
                      <div class="subtitle-2">UWAGA !!!!!!!!</div>
                      <div class="px-4 px-md-10">
                        05.08.2021 w Karpaczu rozpoczyna się XIV EDYCJA POLISH
                        BIKE WEEK W nadchodzący czwartek i piątek 05-06.08.2021
                        na drodze do Karpacza, w Miłkowie przed rondem na
                        wysokości "Pola Campingowego" będę fotografował
                        motocyklistów udających się na zlot.
                      </div>-->
                    </div>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card rounded="xl" height="400">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2520.2370755945362!2d15.482968715745105!3d50.82677247952914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNTDCsDQ5JzM2LjQiTiAxNcKwMjknMDYuNiJF!5e0!3m2!1spl!2spl!4v1590237200779!5m2!1spl!2spl"
                        width="100%"
                        height="100%"
                        frameborder="0"
                        style="border: 0"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      ></iframe>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </v-container>
        </v-theme-provider>
      </v-parallax>
    </section>

    <section id="sessions" v-if="getEvents2024.length > 0">
      <v-container fluid class="pa-0 text-center mt-2">
        <v-carousel v-model="slide" :height="$vuetify.breakpoint.smAndDown ? 850 : 800" class="highContrastControls">
          <!-- <template v-slot:prev="{ on, attrs }">
            <v-btn icon light v-bind="attrs" v-on="on">
              <v-icon size="45">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn icon light v-bind="attrs" v-on="on">
              <v-icon size="45">mdi-chevron-right</v-icon>
            </v-btn>
          </template> -->
          <v-carousel-item v-for="(event, i) in getEvents2024" :key="i" :src="event.downloadUrl">
            <div style="position: relative; z-index: 2; top: 36px">
              <h2 class="display-2 font-weight-bold text-uppercase">
                Sesje fotograficzne 2024
              </h2>

              <v-responsive class="mx-auto mb-8" width="126">
                <v-divider class="mb-1 white" />
              </v-responsive>
            </div>
            <v-sheet class="transparent" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <div class="text-center">
                  <div class="text-md-h2 text-h5 font-weight-bold">
                    {{ event.title }}
                  </div>
                  <div class="text-md-h5 text-h6 mt-md-2 font-weight-bold">
                    {{ event.date }}
                  </div>
                  <event-details :event="event"></event-details>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-container>
    </section>

    <section id="sessions2023" v-if="getEvents2023.length > 0">
      <v-container fluid class="pa-0 text-center mt-2">
        <v-carousel v-model="slide" :height="$vuetify.breakpoint.smAndDown ? 850 : 800" class="highContrastControls">
          <!-- <template v-slot:prev="{ on, attrs }">
            <v-btn icon light v-bind="attrs" v-on="on">
              <v-icon size="45">mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <template v-slot:next="{ on, attrs }">
            <v-btn icon light v-bind="attrs" v-on="on">
              <v-icon size="45">mdi-chevron-right</v-icon>
            </v-btn>
          </template> -->
          <v-carousel-item v-for="(event, i) in getEvents2023" :key="i" :src="event.downloadUrl">
            <div style="position: relative; z-index: 2; top: 36px">
              <h2 class="display-2 font-weight-bold text-uppercase">
                Sesje fotograficzne 2023
              </h2>

              <v-responsive class="mx-auto mb-8" width="126">
                <v-divider class="mb-1 white" />
              </v-responsive>
            </div>
            <v-sheet class="transparent" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <div class="text-center">
                  <div class="text-md-h2 text-h5 font-weight-bold">
                    {{ event.title }}
                  </div>
                  <div class="text-md-h5 text-h6 mt-md-2 font-weight-bold">
                    {{ event.date }}
                  </div>
                  <event-details :event="event"></event-details>
                </div>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-container>
    </section>

    <section id="offer">
      <v-container fluid class="pa-0 text-center mt-2">
        <v-carousel v-model="slideOffer" :height="$vuetify.breakpoint.smAndDown ? 850 : 800">
          <v-carousel-item v-for="(offer, i) in getOffer" :key="i" :src="offer.imageUrl">
            <div style="position: relative; z-index: 2; top: 36px">
              <h2 class="display-2 font-weight-bold text-uppercase">Oferta</h2>

              <v-responsive class="mx-auto mb-8" width="126">
                <v-divider class="mb-1 white" />
              </v-responsive>
            </div>
            <v-sheet class="transparent" height="100%">
              <v-row class="fill-height" align="center" justify="center">
                <v-card
                  class="text-center pa-5 glass-pane"
                  rounded="xl"
                  :max-width="$vuetify.breakpoint.mobile ? 350 : 550"
                >
                  <div class="text-md-h2 font-weight-bold" :class="$vuetify.breakpoint.mobile ? 'text-h4' : 'text-h5'">
                    Fotografia {{ offer.title.toLowerCase() }}
                  </div>
                  <div class="text-md-h5 text-h6 mt-md-2 font-weight-bold">
                    {{ offer.text }}
                  </div>
                  <v-btn :to="offer.route" large outlined class="mt-3">Dowiedz się więcej</v-btn>
                </v-card>
              </v-row>
            </v-sheet>
          </v-carousel-item>
        </v-carousel>
      </v-container>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventDetails from "@/components/EventDetails";
export default {
  components: {
    EventDetails,
  },
  computed: {
    ...mapGetters(["getEvents2023", "getEvents2024", "getOffer", "getUpComingEvents"]),
  },
  data: () => ({
    slide: 0,
    slideOffer: 0,
    slide2: 0,
    slide3: 0,
    upComingEvent: {
      title: "MOTO-JAKUSZYCE",
      date: "11-12.09.2021",
      text:
        'W nadchodzący weekend w godzinach 11.00 - 17.00 na drodze krajowej nr 3 między Szklarską Porębą a Jakuszycami, zapraszam motocyklistów na sesję fotograficzną. Staram się uchwycić w kadrze każdy przejeżdżający motocykl. „MASZ OCHOTĘ NA FAJNĄ FOTKĘ - PODJEDŹ I POWIEDZ, ALBO DAJ SYGNAŁ KLAKSONEM I PRZEJEDŹ KILKA RAZY !!!"',
    },
  }),
  methods: {
    ...mapActions(["fetchEvents2023", "fetchEvents2024", "fetchUpComingEvents"]),
  },
  beforeMount() {
    this.fetchUpComingEvents();
    this.fetchEvents2024();
    this.fetchEvents2023();
  },
};
</script>

<style>
.glass-pane {
  background-color: rgba(255, 255, 255, 0.35) !important;
  backdrop-filter: blur(5px) !important;
  /*color: #1b95f2 !important;*/
}

.highContrastControls .v-btn--icon {
  color: #fc030b !important;
}
</style>
