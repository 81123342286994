import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

Vue.config.productionTip = false


// Firebase init
const firebaseApp = initializeApp({
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
});

export const db = getFirestore()
const storage = getStorage(firebaseApp)
// const storageRef = ref(storage)
export const sessionsRef = ref(storage, 'sessions')


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
